import React from "react"
import styled from "styled-components"

import Logo from "../../images/logo.png"
import LogoPhrase from "../../images/logo-phrase.png"
import { breakpoints } from "../../css/styles"

const Container = styled.section`
  align-items: center;
  animation: 'body-fade-in' 0.75s forwards;
  animation-delay: 1.2s;
  display: flex;
  height: 100vh;
  flex-direction: column;
  justify-content: center;
  opacity: 0;
  overflow: hidden;
  position: relative;
  z-index: 2;
`
const LogoImg = styled.img`
  border: 3px solid white;
  border-radius: 10px;
  display: block;
  margin: 0;
  width: 75px;

  @media(min-width: ${breakpoints.tablet}) {
    border-radius: 14px;
    width: 125px;
  }
`
const LogoImgPhrase = styled.img`
  display: block;
  margin: 15px 0 0 0;
  width: 160px;

  @media(min-width: ${breakpoints.tablet}) {
    width: 250px;
  }
`
const LogoText = styled.p`
  color: white;
  font-family: 'proxima-nova', sans-serif;
  font-size:28px;
  font-weight: 800;
  margin-top: 15px;

  @media(min-width: ${breakpoints.tablet}) {
    font-size: 40px;
  }
`

const Frame = () => {
  return (
    <>
      <Container>
        <LogoImg src={ Logo } alt="People for Bikes Logo" />
        <LogoImgPhrase src={ LogoPhrase } alt="People for Bikes logotype" />
        <LogoText>Our Key Achievements</LogoText>
      </Container>
    </>
  )
}

export default Frame