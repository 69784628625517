import React from "react"
import { graphql } from "gatsby"
import Img from "gatsby-image"

import Layout from "../components/Global/layout"
import SEO from "../components/Global/seo"

import BlueGradient from '../components/Story/BlueGradient'
import MicroNav from '../components/Story/MicroNav'
import TitleArea from '../components/Story/TitleArea'
import ImageContainer from '../components/Global/ImageContainer'
import Content from '../components/Story/Content'
import GrayGradient from '../components/Story/GrayGradient'
import BlueContent from '../components/Story/BlueContent'
import RideSpotLogo from '../components/Story/RideSpotLogo'
import Spacer from '../components/Story/Spacer'
import Related from '../components/Story/Related'
import RideSpotContainer from '../components/Story/RideSpotContainer'
import RideSpotImgContainer from '../components/Story/RideSpotImgContainer'
import RideSpot from '../components/Global/RideSpot'

// Randomizer, not very random though but random enough
function getRandomNumber(min, max) {
  return Math.random() * (max - min) + min;
}

const Merger = (props) => {

  return (  
    <Layout>
      <SEO title="2019 Merger Accomplishments" />

      {/* Story Section */}
      <BlueGradient>
        <MicroNav />
        <TitleArea
          title="Creating One Strong Voice for the Bicycle Industry"
          lede="By merging with the Bicycle Product Suppliers Association in July 2019, PeopleForBikes has created one united, professional organization to represent the entire bicycle industry."
        />  
        <ImageContainer
          title="Promo image for Merger Announcement"
          alt="Promo image for Merger Announcement"
          fluid= { props.data.mergerImage.childImageSharp.fluid }
        />            
        <Content 
          story={[
            <p key={ getRandomNumber(1,100000) }>The merger of PeopleForBikes Coalition and the Bicycle Product Suppliers Association went into force on July 1, 2019. PeopleForBikes has taken over management of all BPSA business-oriented projects. We are guiding and expanding all of the former BPSA subcommittees that work on key industry issues.</p>,
            <p key={ getRandomNumber(1,100000) }>This new alliance has helped introduce PeopleForBikes' work to bike industry companies that have not traditionally been engaged. Many of these companies are now supporting our efforts to increase bike riding participation and make every bike ride better.</p>,
            <p key={ getRandomNumber(1,100000) }>PeopleForBikes professional staff is now working day by day on a variety of industry projects:</p>,
            <ul key={ getRandomNumber(1,100000) }>
              <li key={ getRandomNumber(1,100000) }>A Research and Statistics subcommittee has been established that is dedicated to providing the market insights needed to ensure a growing industry and participant base.</li>
              <li key={ getRandomNumber(1,100000) }>An ongoing (and highly successful) multi-year campaign to promote e-bike use and ratify standard e-bike regulations in all 50 states and on federal public lands (now also supported by an industry subcommittee).</li>
              <li key={ getRandomNumber(1,100000) }>The production of the annual Bicycle Leadership Conference that brings the industry together to network, learn about business trends and best practices, and work more effectively to make bike riding better for everyone.</li>
              <li key={ getRandomNumber(1,100000) }>A Legal, Legislative and Safety subcommittee that combines PFB professional staff with volunteer bike industry leaders to focus on federal, state and local legislative and regulatory issues.</li>
            </ul>,
          ]} />
        <Spacer />
      </BlueGradient>

      {/* Ridespot Section */}
      <GrayGradient>
        <RideSpotLogo
          title="BPSA + PFB Merger Celebration Ride" 
        />
        <ImageContainer
          title="Google Map of Boulder Route"
          alt="Google Map of Boulder Route"
          fluid={ props.data.mergerMapImage.childImageSharp.fluid }
        />
        <BlueContent 
          location="Boulder, CO"
          distance="8.7 miles"
          elevation="295 feet"
          story='In the summer of 2019, PeopleForBikes merged with the Bicycle Product Suppliers Association to increase the clout of bicycling across the country. The merger streamlines and strengthens our industry’s legislative, legal and research efforts. Our industry now speaks to government leaders at all levels with a more powerful, united voice that fully represents our multi-faceted business.'
          link="https://ridespot.org/rides/105136"
        />  
        <RideSpotImgContainer>
          <Img
            title="Ride imagery for Boulder, CO"
            alt="Ride imagery for Boulder, CO"
            fluid={ props.data.mergerRideImageOne.childImageSharp.fluid }
          />
          <Img
            title="Ride imagery for Boulder, CO"
            alt="Ride imagery for Boulder, CO"
            fluid={ props.data.mergerRideImageTwo.childImageSharp.fluid }
          />
        </RideSpotImgContainer>
        <Spacer />
      </GrayGradient>
      
      { /* Related Content */ }
      <Related 
        prevLocationName="Ride Spot"
        prevLocationPath="/ridespot"
        nextLocationName="e-bikes"
        nextLocationPath="/ebikes"
      /> 

      { /* Ride Spot Promo */ }
      <RideSpotContainer>
        <RideSpot />
      </RideSpotContainer>
      
    </Layout>
  )
}

export default Merger

export const mergerImageQuery = graphql`
  query {
    mergerImage: file(relativePath: { eq: "slides/4-PFB-OneStrongVoice.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1000, quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    },
    mergerMapImage: file(relativePath: { eq: "maps/PFB_RS_Maps_Story4_MAP.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1000, quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    },
    mergerRideImageOne: file(relativePath: { eq: "ride-images/Ride4_Image1.png" }) {
      childImageSharp {
        fluid(maxWidth: 1000, quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    },  
    mergerRideImageTwo: file(relativePath: { eq: "ride-images/Ride4_Image2.png" }) {
      childImageSharp {
        fluid(maxWidth: 500, quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    },       
  }
`