import { Link } from "gatsby"
import React from "react"
import ReactGA from 'react-ga'
import styled from 'styled-components'

import logo from "../../images/logotype.png"
import { colors, breakpoints } from "../../css/styles"

const NavContainer = styled.nav`
  align-items: center;
  background: ${colors.darkestBlue};
  color: #fff;
  display: flex;
  flex-direction: column;
  height: 100px;
  padding: 10px 25px;
  justify-content: space-around;
  top: 0;

  @media (min-width: ${breakpoints.phablet}) {
    flex-direction: row;
    height: 75px;
    justify-content: space-between;
  }
`

const Logo = styled.img`
  margin: 0;
  width: 212px;
`

const NavItemContainer = styled.ul`
  display: flex;
  list-style: none;
  margin: 12px 0 0 0;
  padding: 0; /* overrides the UL standards */

  @media (min-width: ${breakpoints.mobile}) {
    margin: 0;
  }
`

const NavItem = styled.li`
  margin: 0;
  padding: 0 8px;

  &:first-child:after {
    content: " | ";
    padding-left: 12px;
  }
`

const NavItemLink = styled.a`
  color: #fff;
  font-family: 'TradeGothicPro', Arial, Helvetica, sans-serif;
  text-decoration: none;

  &:visited, &:focus {
    color: #fff;
    text-decoration: none;
  }

  &:hover {
    color: #fff;
    text-decoration: underline;
  }
`

const NavItemLinkLast = styled.a`
  color: #fff;
  font-family: 'TradeGothicPro', Arial, Helvetica, sans-serif;
  text-decoration: none;

  &:visited, &:focus {
    color: #fff;
    text-decoration: none;
  }

  &:hover {
    color: #fff;
    text-decoration: underline;
  }
`

class Header extends React.Component {
  logger() {
    // Detect each click of the financial PDF
    ReactGA.event({
      category: 'Financial Download',
      action: 'User clicked link to view financials'
    })
  }

  render() {
    return (
      <>
        <NavContainer className="pfb-header-wrapper">
          <Link to="/">
            <Logo src={logo} alt="People for Bikes Logo" />
          </Link>
          <NavItemContainer>
            <NavItem>
              <NavItemLink 
                href="https://peopleforbikes.org/shortsignup/" 
                target="_blank">
                  Join Us
              </NavItemLink>
            </NavItem>
            <NavItem>
              <NavItemLinkLast 
                href="#" 
                onClick={this.logger} 
                target="_blank" 
                id="pfb-financials-pdf">
                  Financials
              </NavItemLinkLast>
            </NavItem>
          </NavItemContainer>
        </NavContainer>
      </>
    )
  }
}

export default Header