import { keyframes, css } from 'styled-components';

/*
 * NOTE: use a six-character hex code for all colors to allow alpha channel
 * adjustment without adding extra vars and/or a color manipulation lib.
 *
 * Example:
 * // use the brand color at 25% opacity
 * border-color: ${colors.red}25;
 */

// Variables 
export const colors = { 
  darkestBlue: `#002c40`,
  darkBlue: `#103d54`,
  mediumBlue: `#00a1e1`,
  lightBlue: `#61bbe2`,
  lightestBlue: `#79d0eb`,
  red: `#eb1b23`,
  lightGray: `#d2d2d2`,
  lightestGray: `#e7e7e7`,
  mediumGray: `#898989`,
  darkGray: `#5a5a5a`,
  black: `#0c0c0c`,
}

export const breakpoints = {
  tiny: '350px',
  mobile: '400px',
  phablet: '550px',
  tablet: '768px',
  laptop: '1000px',
  desktop: '1200px',
  hd: '1600px'
};

export const radius = {
  default: 2,
  large: 4
};

// Animations
const simpleEntry = keyframes`
  from {
    opacity: 0;
    transform: translateY(20px);
  }
`
const deadSimpleEntry = keyframes`
  from {
    opacity: .25;
  }
`
export const simpleEntryAnimation = css`
  animation: ${simpleEntry} .75s ease forwards;
`
export const deadSimpleEntryAnimation = css`
  animation: ${deadSimpleEntry} .5s ease forwards;
`