import React from "react"
import { graphql } from "gatsby"
import Img from "gatsby-image"

import Layout from "../components/Global/layout"
import SEO from "../components/Global/seo"

import BlueGradient from '../components/Story/BlueGradient'
import MicroNav from '../components/Story/MicroNav'
import TitleArea from '../components/Story/TitleArea'
import ImageContainer from '../components/Global/ImageContainer'
import Content from '../components/Story/Content'
import GrayGradient from '../components/Story/GrayGradient'
import BlueContent from '../components/Story/BlueContent'
import RideSpotLogo from '../components/Story/RideSpotLogo'
import Spacer from '../components/Story/Spacer'
import Related from '../components/Story/Related'
import RideSpotContainer from '../components/Story/RideSpotContainer'
import RideSpotImgContainer from '../components/Story/RideSpotImgContainer'
import RideSpot from '../components/Global/RideSpot'

// Randomizer, not very random though but random enough
function getRandomNumber(min, max) {
  return Math.random() * (max - min) + min;
}

const Policy = (props) => {

  return (  
    <Layout>
      <SEO title="2019 Policy Accomplishments" />

      {/* Story Section */}
      <BlueGradient>
        <MicroNav />
        <TitleArea
          title="Maximizing Pro-Bike Funding and Policies"
          lede="PeopleForBikes helped engineer increased support to make bicycling better (measured in dollars invested and policies approved) from all levels of government."
        />  
        <ImageContainer
          title="Stylized view of the US Capitol building with streaking lights"
          alt="Stylized view of the US Capitol building with streaking lights"
          fluid= { props.data.policyImage.childImageSharp.fluid }
        />            
        <Content 
          story={[
            <p key={ getRandomNumber(1,100000) }>The professional work of the PeopleForBikes Business Network team and our D.C. -based government relations staffers and advisors was a key factor in keeping federal bike money flowing. PFB also recently launched a broad-based Federal Bike Infrastructure Coalition that is focused on maximizing government support for bicycling in the next multi-year federal transportation bill that is slated for 2020. Our high-profile 2019 Washington events included three successful bike industry fly-ins (meetings with members of Congress) and a Congressional Bike Fest that attracted more than 600 participants. We played a central role in encouraging The U.S. Department of the Interior to adopt a new positive stance on e-bike use in National Parks, National Wildlife Areas and BLM land.</p>,
            <p key={ getRandomNumber(1,100000) }>We monitored more than 20,000 state legislative bills, and we closely tracked 277 bills that focused on bicycle use, bike infrastructure and access. Finally, PFB coordinated much of the lobbying and industry exemption testimony and applications related to newly imposed tariffs on bicycles and bike products made in China. We presented a strong, professional summary  of the many ways that additional tariffs hurt bike businesses and bike customers.</p>,
            <p key={ getRandomNumber(1,100000) }>PeopleForBikes successfully activated our large, influential  nationwide group of grassroots supporters all year. We emailed more than four million calls-to-action on nearly 100 bicycling challenges and opportunities that ultimately influenced elected officials.</p>
          ]} />
        <Spacer />
      </BlueGradient>

      {/* Ridespot Section */}
      <GrayGradient>
        <RideSpotLogo
          title="D.C. Brewery Tour- Four Breweries, 15 miles of fun!" 
        />
        <ImageContainer
          title="Google Map of DC Brewery Tour Route"
          alt="Google Map of DC Brewery Tour Route"
          fluid={ props.data.policyMapImage.childImageSharp.fluid }
        />
        <BlueContent 
          location="Washington, District of Columbia"
          distance="15 miles"
          elevation="630 feet"
          story='Make your ride all about the destination. D.C. is quickly becoming a craft brewery mecca; why not tour them by bike? There are so many great breweries throughout D.C., it’s hard to get to them all. This route hits a few of the best.'
          link="https://ridespot.org/rides/98700"
        />  
        <RideSpotImgContainer>
          <Img
            title="Ride imagery from Washington DC"
            alt="Ride imagery from Washington DC"
            fluid={ props.data.policyRideImageOne.childImageSharp.fluid }
          />
          <Img
            title="Ride imagery from Washington DC"
            alt="Ride imagery from Washington DC"
            fluid={ props.data.policyRideImageTwo.childImageSharp.fluid }
          />
        </RideSpotImgContainer>
        <Spacer />
      </GrayGradient>
      
      { /* Related Content */ }
      <Related 
        prevLocationName="Opportunity"
        prevLocationPath="/opportunity"
        nextLocationName="Quality"
        nextLocationPath="/quality"
      /> 

      { /* Ride Spot Promo */ }
      <RideSpotContainer>
        <RideSpot />
      </RideSpotContainer>
      
    </Layout>
  )
}

export default Policy

export const policyImageQuery = graphql`
  query {
    policyImage: file(relativePath: { eq: "slides/1-PFB-Policy.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1000, quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    },
    policyMapImage: file(relativePath: { eq: "maps/PFB_RS_Maps_Story1_MAP.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1000, quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    },
    policyRideImageOne: file(relativePath: { eq: "ride-images/Ride1_Image1.png" }) {
      childImageSharp {
        fluid(maxWidth: 1000, quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    },  
    policyRideImageTwo: file(relativePath: { eq: "ride-images/Ride1_Image2.png" }) {
      childImageSharp {
        fluid(maxWidth: 500, quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    },       
  }
`