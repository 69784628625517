import React from "react"
import { graphql } from "gatsby"
import Img from "gatsby-image"

import Layout from "../components/Global/layout"
import SEO from "../components/Global/seo"

import BlueGradient from '../components/Story/BlueGradient'
import MicroNav from '../components/Story/MicroNav'
import TitleArea from '../components/Story/TitleArea'
import ImageContainer from '../components/Global/ImageContainer'
import Content from '../components/Story/Content'
import GrayGradient from '../components/Story/GrayGradient'
import BlueContent from '../components/Story/BlueContent'
import RideSpotLogo from '../components/Story/RideSpotLogo'
import Spacer from '../components/Story/Spacer'
import Related from '../components/Story/Related'
import RideSpotContainer from '../components/Story/RideSpotContainer'
import RideSpotImgContainer from '../components/Story/RideSpotImgContainer'
import RideSpot from '../components/Global/RideSpot'

// Randomizer, not very random though but random enough
function getRandomNumber(min, max) {
  return Math.random() * (max - min) + min;
}

const Opportunity = (props) => {

  return (  
    <Layout>
      <SEO title="Helping Cycling Reach Children" />

      {/* Story Section */}
      <BlueGradient>
        <MicroNav />
        <TitleArea
          title="Giving Every Child the Opportunity to Ride"
          lede="PeopleForBikes is working to enhance the work of the best U.S. youth cycling programs."
        />  
        <ImageContainer
          title="Promo image for Children"
          alt="Promo image for Children"
          fluid= { props.data.opportunityImage.childImageSharp.fluid }
        />            
        <Content 
          story={[
            <p key={ getRandomNumber(1,100000) }>PeopleForBikes has a long history of supporting efforts that get kids on bikes. We know that bicycling is an activity that can provide a lifetime of great experiences and benefits. We know that active kids tend to be happier and healthier and do better in school. Most American adults who ride today can point back to great bicycling experiences when they were kids--the feelings of freedom, exhilaration and independence that were part of every two-wheel adventure.</p>,
            <p key={ getRandomNumber(1,100000) }>PeopleForBikes was a key partner and host for the Safe Routes to School National Partnership that began in 2006 in conjunction with a major federal program to encourage kids to bike and walk to school. That effort expanded and thrived and now operates independently as an effective broad-based program.</p>,
            <p key={ getRandomNumber(1,100000) }>Youth cycling is benefitting from the great work of a variety of bold professional organizations. We are partnering with these groups now: Free Bikes 4 Kidz, Little Bellas, NICA (the National Interscholastic Cycling Association), Project Bike Tech, Safe Routes Partnership, Trips For Kids, USA BMX and USA Cycling.</p>,
            <p key={ getRandomNumber(1,100000) }>Thanks to generous support from our USA Cycling and bike industry leaders, we have hired a Youth Cycling Manager to coordinate a cooperative youth cycling pilot program that will engage and link all of these programs in one city location. Our hope is to identify the best practices of this pilot and export them to other cities while enhancing the work of all of these great organizations.</p>,
          ]} />
        <Spacer />
      </BlueGradient>

      {/* Ridespot Section */}
      <GrayGradient>
        <RideSpotLogo
          title="Slaughter Pen" 
        />
        <ImageContainer
          title="Google Map of Bentonville, AR Route"
          alt="Google Map of Bentonville, AR Route"
          fluid={ props.data.opportunityMapImage.childImageSharp.fluid }
        />
        <BlueContent 
          location="Bentonville, AR"
          distance="11 miles"
          elevation="No Data"
          story="Slaughter Pen is the ultimate urban mountain bike playground starting from downtown Bentonville, an IMBA Silver Level Ride Center. Truly a trail system with something for everyone. Clean flow, major downhills, technical rocks, and huge berms. Swing by the Onyx Coffee Lab after your ride for an espresso or Ozark Beer Co. brew."
          link="https://ridespot.org/rides/4606"
        />  
        <RideSpotImgContainer>
          <Img
            title="Ride imagery for Bentonville, AR"
            alt="Ride imagery for Bentonville, AR"
            fluid={ props.data.opportunityRideImageOne.childImageSharp.fluid }
          />
          <Img
            title="Ride imagery for Bentonville, AR"
            alt="Ride imagery for Bentonville, AR"
            fluid={ props.data.opportunityRideImageTwo.childImageSharp.fluid }
          />
        </RideSpotImgContainer>
        <Spacer />
      </GrayGradient>
      
      { /* Related Content */ }
      <Related 
        prevLocationName="Investment"
        prevLocationPath="/investment"
        nextLocationName="Policy"
        nextLocationPath="/policy"
      /> 

      { /* Ride Spot Promo */ }
      <RideSpotContainer>
        <RideSpot />
      </RideSpotContainer>
      
    </Layout>
  )
}

export default Opportunity

export const opportunityImageQuery = graphql`
  query {
    opportunityImage: file(relativePath: { eq: "slides/8-PFB-Kids.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1000, quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    },
    opportunityMapImage: file(relativePath: { eq: "maps/PFB_RS_Maps_Story8_MAP.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1000, quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    },
    opportunityRideImageOne: file(relativePath: { eq: "ride-images/Ride8_Image1.png" }) {
      childImageSharp {
        fluid(maxWidth: 1000, quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    },  
    opportunityRideImageTwo: file(relativePath: { eq: "ride-images/Ride8_Image2.png" }) {
      childImageSharp {
        fluid(maxWidth: 500, quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    },       
  }
`