import React from "react"
import styled from "styled-components"

import Streets from '../../images/streets-2.svg'

import { breakpoints, colors } from "../../css/styles"

const Gradient = styled.section`
  background-image: url(${ Streets }), linear-gradient(
    ${colors.mediumBlue}, 
    ${colors.mediumBlue}
  );
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;
  position: fixed;
  width: 100%;
  z-index: -1;

  @media (min-width: ${ breakpoints.tablet }) {
    display: none;
  }
`
const MobileBlueGradient = ({ children }) => {
  return (
    <>
      <Gradient>
        { children }
      </Gradient>
    </>
  )
}

export default MobileBlueGradient