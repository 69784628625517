import React from "react"
import styled from "styled-components"
import Img from "gatsby-image"

import { breakpoints } from "../../css/styles"

const Wrapper = styled(Img)`
  position: relative;
  margin: 2rem -50%;
  left: 50%;
  right: 50%;
  width: 100%;

  @media (min-width: ${ breakpoints.tablet }) {
    left: 69vw;
    right: 50vw;
    width: 60vw;
  }
`

// This is a pretty janky component, but it does enough for us
// Just make sure the images stay in the content window - fin
// It doesn't fully support all the props possible from gatsby-image so it's misleading
// You could add more support: https://www.gatsbyjs.org/packages/gatsby-image/#gatsby-image-props
const ImageContainer = (props) => {
  return (
    <>
      <Wrapper 
        title={ props.title }
        alt={ props.alt }
        fluid={ props.fluid }
      />
    </>
  )
}

export default ImageContainer