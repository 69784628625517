import React from "react"
import styled from "styled-components"

import { breakpoints } from "../../css/styles"

const Container = styled.section`
  align-items: center;
  animation: 'body-fade-in' 0.75s forwards;
  animation-delay: 0.6s;
  background-repeat: no-repeat;
  background-size: cover;
  color: rgba(255,255,255,0.5);
  display: flex;
  font-family: 'rift', sans-serif;
  font-size: 70vw;
  height: 100vh;
  justify-content: center;
  left: 50%;
  letter-spacing: -4vw;
  line-height: 1;
  margin: 0 auto;
  max-width: 1200px;
  opacity: 0;
  top: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
  z-index: 1;

  @media(min-width: ${breakpoints.tablet} ) {
    position: absolute;
  }

  /* Above 1200px the font gets nuts, so just make it static */
  @media(min-width: ${breakpoints.desktop} ) {
    font-size: 800px;
    letter-spacing: -40px;
  }
`

const IntroYear = () => {
  return (
    <>
      <Container>
        2019
      </Container>
    </>
  )
}

export default IntroYear