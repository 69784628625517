import React from "react"
import { useStaticQuery, graphql } from "gatsby"

// Need these because we're not including Layout component on homepage
import "../css/layout.css"
import "../css/fonts.css"

import SEO from "../components/Global/seo"
import MobileBlueGradient from "../components/Homepage/MobileBlueGradient"
import Frame from "../components/Homepage/Frame"
import IntroYear from "../components/Homepage/IntroYear"
import IntroLogo from "../components/Homepage/IntroLogo"
import IntroArrow from "../components/Homepage/IntroArrow"
import RideSpotSlide from "../components/Homepage/RideSpotSlide"
import RideSpot from '../components/Global/RideSpot'
import Slide from "../components/Homepage/Slide"
import SlideText from "../components/Homepage/SlideText"
import SlideMapImage from "../components/Homepage/SlideMapImage"
import Footer from "../components/Global/footer"

// Image Set
import MapWashington from "../images/hp-slides/01_PFB_2019Map_Washington.png"
import MapTucson from "../images/hp-slides/02_PFB_2019Map_Tucson.png"
import MapBoulder from "../images/hp-slides/03_PFB_2019Map_Boulder.png"
import MapTahoe from "../images/hp-slides/05_PFB_2019Map_Tahoe.png"
import MapAustin from "../images/hp-slides/06_PFB_2019Map_Austin.png"
import MapMoab from "../images/hp-slides/07_PFB_2019Map_Moab.png"
import MapBentonville from "../images/hp-slides/08_PFB_2019Map_Bentonville.png"


const IndexPage = () => {
  const data = useStaticQuery(graphql`
    query IndexPageTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <>  
      <SEO title={data.site.siteMetadata.title} />
      <MobileBlueGradient />
      <Frame>
        {/** 
          * Part of you is probably saying, 'Why don't the Intro components have a container?' 
          * Look at their styling, IntroYear + IntroLogo are both positioned relative & height 100vh
          * They take the space as a slide by default but can still move as you scroll
          * IntroArrow is absolutely positioned to the bottom of Intro sequence, so it's fine.
          */}
        <IntroYear />
        <IntroLogo />
        <IntroArrow />

        <RideSpotSlide>
          <RideSpot />
        </RideSpotSlide>
        
        {/* 1) Policy - DC */}
        <Slide>
          <SlideMapImage
            imageSource={ MapWashington }
            imageAltText="Stylized US map highlighting Washington DC"
          />
          <SlideText
            title="Maximizing Pro-Bike Funding and Policies"
            lede="PeopleForBikes helped engineer increased support to make bicycling better (measured in dollars invested and policies approved) from all levels of government."
            body="During fiscal year 2019, the federal government once again invested more than $900 million to build 1,500+ bike projects. This Department of Transportation commitment was more than doubled by states, counties, cities and towns nationwide. In sum, government agencies devoted more than $2 billion to enhancing bike riding on streets, trails and in parks&mdash;making it safer, easier to access, and more appealing. On one of our recent trips to Washington D.C. we took industry leaders on a 15 mile ride to see bike infrastructure and stopped for libations along the way."
            path="/policy"
          />
        </Slide>

        {/* 2) Quality - Tucson */}
        <Slide>
          <SlideMapImage
            imageSource={ MapTucson }
            imageAltText="Stylized US map highlighting Tucson AZ"
          />
          <SlideText
            title="Helping Cities Quickly Become Better for Bicycling"
            lede="PeopleForBikes rated the quality of bike riding in more than 500 U.S. cities, provided strategic advice to city leaders on quick improvements, and made major investments in a dozen U.S. locations."
            body="Our focus on cities includes the PlacesForBikes program, the Big Jump project that is designed to produce quick growth for bike riding in sections of large U.S. cities, and the Final Mile program that combines polling, messaging and advertising to support pro-bike city leaders. All of our city work builds on our data-driven backbone that includes PFB's Bicycle Network Analysis tool and our growing City Ratings program. In Tucson, AZ, the Big Jump has produced great results on and off-road."
            path="/quality"
          />
        </Slide>

        {/* 3) Ridespot / Boulder */}
        <Slide>
          <SlideMapImage
            imageSource={ MapBoulder }
            imageAltText="Stylized US map highlighting Boulder CO"
          />
          <SlideText
            title="Introducing Ride Spot, Our Program to Help Every Bike Rider"
            lede="PeopleForBikes is  working to provide people with the information they need to discover and enjoy great bike rides and bike events wherever they live and every place they visit."
            body="Our new Ride Spot app and riding encouragement program is now off the ground and being used by nearly 20,000 individuals and almost 1,000 bicycle retailers. Ride Spot shares the locations and details of all the new (and existing) bike infrastructure and complete networks that make riding safer and more appealing--both on- and off-road. Ride Spot provides essential advice to help people--especially new and occasional riders--overcome the barriers that stop them from riding and riding often. The Ride Spot program development team loves tacos so it was natural that one of our first Ride Stories tracked our path to a great bite to eat. "
            path="/ridespot"
          />
        </Slide>

        {/* 4) Merger / Boulder 2 */}
        <Slide>
          <SlideMapImage
            imageSource={ MapBoulder }
            imageAltText="Stylized US map highlighting Boulder CO"
          />
          <SlideText
            title="Creating One Strong Voice for the Bicycle Industry"
            lede="By merging with the Bicycle Product Suppliers Association in July 2019, PeopleForBikes has created one united, professional organization to represent the entire bicycle industry."
            body="Bicycle businesses face so many challenges and opportunities: navigating trade and tariff issues, understanding market trends, tracking riding participation, enhancing bike safety, and complying with a broad range of (fast-changing) government regulations. PeopleForBikes now guides all of this work. The bike industry&mdash;and in fact, all bike riders&mdash;will benefit. In July we welcomed leaders from PeopleForBikes and BPSA to take a ride with us near our headquarters in Boulder, Colorado - you can follow the route on Ride Spot."
            path="/merger"
          />
        </Slide>

        {/* 5) Ebikes / South Lake Tahoe, CA */}
        <Slide>
          <SlideMapImage
            imageSource={ MapTahoe }
            imageAltText="Stylized US map highlighting South Lake Tahoe CA"
          />
          <SlideText
            title="Growing e-bike Use by Partnering with States and Federal Agencies"
            lede="PeopleForBikes continues to lead the industry in promoting and supporting e-bike use."
            body="Twelve more U.S. states have adopted the industry-standard three-class e-bike classification system that clarifies appropriate use. We recently conducted our sixth E-Bike Summit, a networking and educational gathering of e-bike industry leaders. E-bike use (and sales) continue to grow quickly nationwide...and beyond. An example of this work in action is ride on an e-bike in Lake Tahoe."
            path="/ebikes"
          />
        </Slide>

        {/* 6) Measurement / Bee Cave, Texas */}
        <Slide>
          <SlideMapImage
            imageSource={ MapAustin }
            imageAltText="Stylized US map highlighting Austin TX"
          />
          <SlideText
            title="Improving Our Industry Intelligence, Research, and Stats Efforts"
            lede="PeopleForBikes is answering the key questions that will shape the future of bicycling: Who rides bikes? How does better infrastructure help? What are the trends that influence the key bike business?"
            body="Here’s one of our favorite mantras: What gets measured gets done. But it’s really difficult to track bike riding and all elements of the bike business. This year, the professional PeopleForBikes staff research team has expanded our research and stats efforts. We want to help cities, assist the bike industry, and encourage government agencies at all levels to better understand how Americans are riding bikes and how that riding benefits individuals, communities, our nation and our planet. In an effort to get more people on bikes, we caught up with a group of beginner riders in Austin, Texas learning to navigate city streets on two wheels."
            path="/measurement"
          />
        </Slide>

        {/* 7) Investment / Moab. Utah */}
        <Slide>
          <SlideMapImage
            imageSource={ MapMoab }
            imageAltText="Stylized US map highlighting Moab UT"
          />
          <SlideText
            title="Investing in Great Projects and Programs Through Grants"
            lede="PeopleForBikes will contribute more than $700,000 cash to outstanding bike projects and bike organizations this year."
            body="Our time-tested Community Grants program will invest more than $100,000 in local bike projects in 2019. Our second round of ‘19 grants was devoted to bike park development. We also invested more than $350,000 this year in Bike Sharing Equity and in top national and local non-profit organizations that are working effectively to improve all types of bike riding. An example of the impact of our grants is the SR 128 Bike Path and bike and pedestrian bridge in Moab, Utah."
            path="/investment"
          />
        </Slide>

        {/* 8) Opportunity / Bentonville, AR */}
        <Slide>
          <SlideMapImage
            imageSource={ MapBentonville }
            imageAltText="Stylized US map highlighting Bentonville AR"
          />
          <SlideText
            title="Giving Every Child the Opportunity to Ride"
            lede="PeopleForBikes is working to enhance the work of the best U.S. youth cycling programs."
            body="PeopleForBikes convened a Youth Participation workshop in Bentonville, Arkansas, and arranged for the participation of key national nonprofit organizations that are working to support and grow bicycling for kids. Out of this get-together came a plan to encourage and guide cooperative work among the national groups in one key location. The future of bicycling will depend heavily on kids riding bikes: children who ride are three times more likely to ride as adults than kids who don't. Bentonville, AR is not only home to a world-class urban mountain bike park and it’s becoming a showcase for strategies to get more kids on bikes."
            path="/opportunity"
          />
        </Slide> 
        <Footer />                                                       
      </Frame>
      
    </>
  )
}

export default IndexPage