import React from "react"
import { graphql } from "gatsby"
import Img from "gatsby-image"

import Layout from "../components/Global/layout"
import SEO from "../components/Global/seo"

import BlueGradient from '../components/Story/BlueGradient'
import MicroNav from '../components/Story/MicroNav'
import TitleArea from '../components/Story/TitleArea'
import ImageContainer from '../components/Global/ImageContainer'
import Content from '../components/Story/Content'
import GrayGradient from '../components/Story/GrayGradient'
import BlueContent from '../components/Story/BlueContent'
import RideSpotLogo from '../components/Story/RideSpotLogo'
import Spacer from '../components/Story/Spacer'
import Related from '../components/Story/Related'
import RideSpotContainer from '../components/Story/RideSpotContainer'
import RideSpotImgContainer from '../components/Story/RideSpotImgContainer'
import RideSpot from '../components/Global/RideSpot'

// Randomizer, not very random though but random enough
function getRandomNumber(min, max) {
  return Math.random() * (max - min) + min;
}

const Investment = (props) => {

  return (  
    <Layout>
      <SEO title="Community Grants Success" />

      {/* Story Section */}
      <BlueGradient>
        <MicroNav />
        <TitleArea
          title="Investing in Great Project and Programs Through Grants"
          lede="PeopleForBikes will contribute more than $700,000 cash to outstanding bike projects and bike organizations this year."
        />  
        <ImageContainer
          title="Promo image for Community Grants Program"
          alt="Promo image for Community Grants Program"
          fluid= { props.data.investmentImage.childImageSharp.fluid }
        />            
        <Content 
          story={[
            <p key={ getRandomNumber(1,100000) }>Our Community Grants program has now awarded more than $3.5 million in cash grants to great bike projects in all 50 states. The second round of 2019 grants was devoted to supporting bike parks--a type of bike infrastructure that has broad appeal and provides varied riding opportunities close to home for families who live in cities. Our additional grant programs have added more than $7 million to our contributions, with an emphasis on supporting national groups that have established bicycling best practices for children, for mountain biking, and for engaging individuals as bike advocates.</p>
          ]} />
        <Spacer />
      </BlueGradient>

      {/* Ridespot Section */}
      <GrayGradient>
        <RideSpotLogo
          title="Moab Bike Path Cruise" 
        />
        <ImageContainer
          title="Google Map of Moab Route"
          alt="Google Map of Moab Route"
          fluid={ props.data.investmentMapImage.childImageSharp.fluid }
        />
        <BlueContent 
          location="Moab, UT"
          distance="32 miles"
          elevation="955 feet"
          link="https://ridespot.org/rides/138448"
        />  
        <RideSpotImgContainer>
          <Img
            title="Ride imagery for Moab, UT"
            alt="Ride imagery for Moab, UT"
            fluid={ props.data.investmentRideImageOne.childImageSharp.fluid }
          />
        </RideSpotImgContainer>
        <Spacer />
      </GrayGradient>
      
      { /* Related Content */ }
      <Related 
        prevLocationName="Measurement"
        prevLocationPath="/measurement"
        nextLocationName="Opportunity"
        nextLocationPath="/opportunity"
      /> 

      { /* Ride Spot Promo */ }
      <RideSpotContainer>
        <RideSpot />
      </RideSpotContainer>
      
    </Layout>
  )
}

export default Investment

export const investmentImageQuery = graphql`
  query {
    investmentImage: file(relativePath: { eq: "slides/7-PFB-Projects.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1000, quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    },
    investmentMapImage: file(relativePath: { eq: "maps/PFB_RS_Maps_Story7_MAP.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1000, quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    },
    investmentRideImageOne: file(relativePath: { eq: "ride-images/Ride7_Image2.png" }) {
      childImageSharp {
        fluid(maxWidth: 1000, quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    },        
  }
`