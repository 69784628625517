import React from "react"
import styled from "styled-components"

const Space = styled.section`
  width: 100%;
  padding-bottom: 50px;
`

const Spacer = () => {
  return (
    <>
      <Space />
    </>
  )
}

export default Spacer