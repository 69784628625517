import React from "react"
import styled from "styled-components"

import { colors } from "../../css/styles"

const Gradient = styled.section`
  background-image: linear-gradient(
    ${colors.lightestGray}, 
    ${colors.lightGray}
  );
`
const GrayGradient = ({ children }) => {
  return (
    <>
      <Gradient>
        { children }
      </Gradient>
    </>
  )
}

export default GrayGradient