import React from "react"
import { graphql } from "gatsby"
import Img from "gatsby-image"

import Layout from "../components/Global/layout"
import SEO from "../components/Global/seo"

import BlueGradient from '../components/Story/BlueGradient'
import MicroNav from '../components/Story/MicroNav'
import TitleArea from '../components/Story/TitleArea'
import ImageContainer from '../components/Global/ImageContainer'
import Content from '../components/Story/Content'
import GrayGradient from '../components/Story/GrayGradient'
import BlueContent from '../components/Story/BlueContent'
import RideSpotLogo from '../components/Story/RideSpotLogo'
import Spacer from '../components/Story/Spacer'
import Related from '../components/Story/Related'
import RideSpotContainer from '../components/Story/RideSpotContainer'
import RideSpotImgContainer from '../components/Story/RideSpotImgContainer'
import RideSpot from '../components/Global/RideSpot'

// Randomizer, not very random though but random enough
function getRandomNumber(min, max) {
  return Math.random() * (max - min) + min;
}

const Measurement = (props) => {

  return (  
    <Layout>
      <SEO title="Better Metrics to Move Cycling Forward" />

      {/* Story Section */}
      <BlueGradient>
        <MicroNav />
        <TitleArea
          title="Improving Our Industry Intelligence, Research, and Stats Efforts"
          lede="PeopleForBikes is answering the key questions that will shape the future of bicycling: Who rides bikes? How does better infrastructure help? What are the trends that influence the key bike business?"
        />  
        <ImageContainer
          title="Promo image for Measurement Announcement"
          alt="Promo image for Measurement Announcement"
          fluid= { props.data.mergerImage.childImageSharp.fluid }
        />            
        <Content 
          story={[
            <p key={ getRandomNumber(1,100000) }>Americans make more than four billion bike trips every year (and that doesn’t even count all recreational rides, particularly those that are ridden on dirt). These rides start and finish at unique locations, follow an infinite number of routes, and most never get recorded or counted. </p>,
            <p key={ getRandomNumber(1,100000) }>Mindful of this, PeopleForBikes coordinates an every-other-year study of U.S. bicycling participation: we’ve conducted this three times. We also survey our individual supporters to learn about their riding habits and what they think about safety, equipment and participating in bike events.</p>,
            <p key={ getRandomNumber(1,100000) }>We ask U.S. city staffers to complete a City Snapshot that summarizes their bike infrastructure--currently in place and planned for the near future. We ask individual bike riders to rate the quality of bike riding experiences in their hometowns by completing our Community Survey. Both of these interactive tools have benefited from record numbers of responses in 2019. We've also expanded our Bicycle Network Analysis scoring to major Canadian cities.</p>,
            <p key={ getRandomNumber(1,100000) }>Expanding our measurement efforts is crucial in securing additional support for bicycling from government partners at all levels.</p>,
          ]} />
        <Spacer />
      </BlueGradient>

      {/* Ridespot Section */}
      <GrayGradient>
        <RideSpotLogo
          title="Bicycle Sport Shop - Bee Cave Women’s Beginner Ride (Easy)" 
        />
        <ImageContainer
          title="Google Map of Austin Route"
          alt="Google Map of Austin Route"
          fluid={ props.data.mergerMapImage.childImageSharp.fluid }
        />
        <BlueContent 
          location="Bee Cave, TX"
          distance="7.5 miles"
          elevation="373 feet"
          link="https://ridespot.org/rides/106882"
        />  
        <RideSpotImgContainer>
          <Img
            title="Ride imagery for Bee Cave, TX"
            alt="Ride imagery for Bee Cave, TX"
            fluid={ props.data.mergerRideImageOne.childImageSharp.fluid }
          />
          <Img
            title="Ride imagery for Bee Cave, TX"
            alt="Ride imagery for Bee Cave, TX"
            fluid={ props.data.mergerRideImageTwo.childImageSharp.fluid }
          />
        </RideSpotImgContainer>
        <Spacer />
      </GrayGradient>
      
      { /* Related Content */ }
      <Related 
        prevLocationName="e-bikes"
        prevLocationPath="/ebikes"
        nextLocationName="Investment"
        nextLocationPath="/investment"
      /> 

      { /* Ride Spot Promo */ }
      <RideSpotContainer>
        <RideSpot />
      </RideSpotContainer>
      
    </Layout>
  )
}

export default Measurement

export const mergerImageQuery = graphql`
  query {
    mergerImage: file(relativePath: { eq: "slides/6-PFB-Data.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1000, quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    },
    mergerMapImage: file(relativePath: { eq: "maps/PFB_RS_Maps_Story6_MAP.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1000, quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    },
    mergerRideImageOne: file(relativePath: { eq: "ride-images/Ride6_Image1.png" }) {
      childImageSharp {
        fluid(maxWidth: 1000, quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    },  
    mergerRideImageTwo: file(relativePath: { eq: "ride-images/Ride6_Image2.png" }) {
      childImageSharp {
        fluid(maxWidth: 500, quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    },       
  }
`