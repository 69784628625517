import React from "react"
import { graphql } from "gatsby"
import Img from "gatsby-image"

import Layout from "../components/Global/layout"
import SEO from "../components/Global/seo"

import BlueGradient from '../components/Story/BlueGradient'
import MicroNav from '../components/Story/MicroNav'
import TitleArea from '../components/Story/TitleArea'
import ImageContainer from '../components/Global/ImageContainer'
import Content from '../components/Story/Content'
import GrayGradient from '../components/Story/GrayGradient'
import BlueContent from '../components/Story/BlueContent'
import RideSpotLogo from '../components/Story/RideSpotLogo'
import Spacer from '../components/Story/Spacer'
import Related from '../components/Story/Related'
import RideSpotContainer from '../components/Story/RideSpotContainer'
import RideSpotImgContainer from '../components/Story/RideSpotImgContainer'
import RideSpot from '../components/Global/RideSpot'

// Randomizer, not very random though but random enough
function getRandomNumber(min, max) {
  return Math.random() * (max - min) + min;
}

const Quality = (props) => {

  return (  
    <Layout>
      <SEO title="2019 Policy Accomplishments" />

      {/* Story Section */}
      <BlueGradient>
        <MicroNav />
        <TitleArea
          title="Helping Cities Quickly Become Better for Bicycling"
          lede="PeopleForBikes rated the quality of bike riding in more than 500 U.S. cities, provided strategic advice to city leaders on quick improvements, and made major investments in a dozen U.S. locations."
        />  
        <ImageContainer
          title="Guy in bike lane with tree"
          alt="Guy in bike lane with tree"
          fluid= { props.data.qualityImage.childImageSharp.fluid }
        />            
        <Content 
          story={[
            <p key={ getRandomNumber(1,100000) }>PeopleForBikes works to improve all types of bicycling, with a steady focus on both recreational and transportation-oriented riding.</p>,
            <p key={ getRandomNumber(1,100000) }>In sum, PeopleForBikes invested more than $5 million in 2019 to develop complete networks, safer infrastructure, bike parks and trails of all kinds in U.S. cities and towns. We produced and distributed ratings for more than 500 U.S. cities--scores that benefited from reviews provided by local bike riders, spending and planning summaries provided by city staffers, plus statistics and government data derived from a broad mix of credible sources.</p>,
            <p key={ getRandomNumber(1,100000) }>The PeopleForBikes City Ratings methodology is now being applied in Canada. Our BNA methodology and maps have been exported  to Canada, Australia and (soon) to other nations. Our ratings are data-driven--based on safety statistics, participation counts, bike network evaluations (BNA), plus reports provided by cities on future plans and investments. We also grade cities for their success in providing low-stress bike riding opportunities to residents of all neighborhoods citywide.</p>,
            <p key={ getRandomNumber(1,100000) }>In 2019, we distributed $200,000 in cash grants to local non-profit partners to support bicycle network implementation in 10 Big Jump cities.</p>,
            <p key={ getRandomNumber(1,100000) }>We hosted 100 U.S. city leaders on study tours to cities that stand as outstanding international and domestic examples of great cycling experiences and programs. We launched our new PlacesForBikes Workshops, reaching 450 city leaders across eight U.S. cities.</p>,
          ]} />
        <Spacer />
      </BlueGradient>

      {/* Ridespot Section */}
      <GrayGradient>
        <RideSpotLogo
          title="Eszter Horanyi’s Camp Tucson Experience" 
        />
        <ImageContainer
          title="Google Map of Tucson Route"
          alt="Google Map of Tucson Route"
          fluid={ props.data.qualityMapImage.childImageSharp.fluid }
        />
        <BlueContent 
          location="Tucson, AZ"
          distance="36 miles"
          elevation="No data"
          story='Camp Tucson is becoming a bit of a spring tradition down in the desert. Originally conceived by Scott Morris as a way to encourage people to come visit the Sonoran Desert during one of the nicest times of the year, "camp" consists of three self-supported rides of various lengths designed to showcase the best of the Tucson area while offering valuable recon and training for those aiming to race the Arizona Trail Race in April.'
          link="https://ridespot.org/rides/8013"
        />  
        <RideSpotImgContainer>
          <Img
            title="Ride imagery for Tucson, AZ"
            alt="Ride imagery for Tucson, AZ"
            fluid={ props.data.qualityRideImageOne.childImageSharp.fluid }
          />
          <Img
            title="Ride imagery for Tucson, AZ"
            alt="Ride imagery for Tucson, AZ"
            fluid={ props.data.qualityRideImageTwo.childImageSharp.fluid }
          />
        </RideSpotImgContainer>
        <Spacer />
      </GrayGradient>
      
      { /* Related Content */ }
      <Related 
        prevLocationName="Policy"
        prevLocationPath="/policy"
        nextLocationName="Ride Spot"
        nextLocationPath="/ridespot"
      /> 

      { /* Ride Spot Promo */ }
      <RideSpotContainer>
        <RideSpot />
      </RideSpotContainer>
      
    </Layout>
  )
}

export default Quality

export const qualityImageQuery = graphql`
  query {
    qualityImage: file(relativePath: { eq: "slides/2-PFB-HelpingCities.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1000, quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    },
    qualityMapImage: file(relativePath: { eq: "maps/PFB_RS_Maps_Story2_MAP.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1000, quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    },
    qualityRideImageOne: file(relativePath: { eq: "ride-images/Ride2_Image1.png" }) {
      childImageSharp {
        fluid(maxWidth: 1000, quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    },  
    qualityRideImageTwo: file(relativePath: { eq: "ride-images/Ride2_Image2.png" }) {
      childImageSharp {
        fluid(maxWidth: 500, quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    },       
  }
`