import React from "react"
import styled from "styled-components"

import { breakpoints, colors } from '../../css/styles'

import PhoneLeft from '../../images/ride-story-left.png'
import PhoneRight from '../../images/ride-story-right.png'
import Logo from '../../images/ride-spot-logo-promo.png'

// Phones
const PhoneContainer = styled.section`
  display: flex;
`

const PhoneImageLeft = styled.img`
  display: none;
  height: 413px;
  margin: 0 8px;
  width: 200px;

  @media (min-width: ${ breakpoints.phablet} ) {
    display: block;
  }
`

const PhoneImageRight = styled.img`
  height: 413px;
  margin: 0 8px;
  width: 200px;
`

// Details
const DetailsContainer = styled.section`
  margin: 0 15px;
  max-width: 300px;

  @media (min-width: ${ breakpoints.phablet }) {
    max-width: 400px;
  }
`

const LogoImg = styled.img`
  display: block;
  margin: 25px auto;
  width: 300px;

  @media (min-width: ${ breakpoints.laptop }) {
    margin: 25px 0;
    max-width: 300px;
  }
`

const Text = styled.p`
  color: white;
  display: none;
  font-size: 18px;
  line-height: 1.2;
  margin: 0 0 25px 0;
  text-align: center;

  @media (min-width: ${ breakpoints.laptop }) {
    display: block;
    text-align: left;
  }
`
const TextBold = styled(Text)`
  display: block;
  font-family: 'proxima-nova', sans-serif;
  font-weight: 800;
`
const Button = styled.a`
  background-color: ${ colors.lightestGray };
  border-radius: 10px;
  color: black;
  cursor: pointer;
  display: block;
  font-weight: bold;
  padding: 15px 10px;
  margin: 0 auto;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  transition: 0.2s ease-in-out;
  width: 270px;

  &:hover {
    background-color: ${ colors.lightGray };
    text-decoration: none;
  }

  @media (min-width: ${ breakpoints.laptop }) {
    margin: 0;
  }
`

class RideSpot extends React.Component {
  render() {
    return (
      <>
        <PhoneContainer>
          <PhoneImageLeft src={ PhoneLeft } alt="RideSpot Ride Details on Mobile" />
          <PhoneImageRight src={ PhoneRight } alt="RideSpot Directions on Mobile" />
        </PhoneContainer>
        <DetailsContainer>
          <LogoImg src={ Logo } alt="Ride Spot Logo"/>
          <TextBold>PeopleForBikes, the dynamic voice for bicycle advocacy in America, created Ride Spot to reduce barriers to riding and inspire you to ride!</TextBold>
          <Text>People can easily find safe and fun routes, track their rides, add stories and photos and then share with other riders. Discover upcoming group rides and exciting route-based Challenges sponsored by local businesses. </Text>
          <Button href="https://ridespot.org/" target="_blank" noopener noreferrer>Download The Ride Spot App</Button>
        </DetailsContainer>
      </>
    )
  }
}

export default RideSpot