import React from "react"
import styled from "styled-components"

import { breakpoints, colors } from "../../css/styles"
import Streets from '../../images/streets-2.svg'

const Container = styled.main`
  animation: 'body-fade-in' 0.75s forwards; 
  animation-delay: 0.25s;
  height: 100vh;
  opacity: 0;
  width: 100%;

  @media (min-width: ${breakpoints.tablet}) {
    background-attachment: fixed;
    background-image: url(${ Streets }), linear-gradient(
      ${colors.lightBlue}, 
      ${colors.darkBlue}
    );
    background-repeat: no-repeat;
    background-size: cover;
    height: inherit;
    position: static;
  }
`

const Frame = ({ children }) => {
  return (
    <>
      <Container>
        { children }
      </Container>
    </>
  )
}

export default Frame