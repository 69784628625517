import React from "react"
import styled from "styled-components"

import { breakpoints } from "../../css/styles"

const Article = styled.article`
  margin: 0% 8%;

  @media (min-width: ${breakpoints.mobile}) and (max-width: ${breakpoints.tablet}) {
    margin: 0% 10%;
  }

  @media (min-width: ${breakpoints.tablet}) {
    margin: 0 20%;
  }
`
const ContentMain = styled.div`
  color: #fff;
  font-size: 20px;
  line-height: 1.4;
  margin: 0;

  a, a:visited, a:hover, a:focus {
    color: #fff;
    text-decoration: underline;
  }
`

class Content extends React.Component {
  render() {
    return (
      <>
        <Article>
          <ContentMain> { this.props.story } </ContentMain>
        </Article>
      </>
    )
  }
}

export default Content