import React from "react"
import styled from "styled-components"

import logo from "../../images/logo.png"
import { breakpoints, colors } from "../../css/styles"

/* Signup */
const FooterContainer = styled.footer`
  align-items: center;
  background-color: black;
  display: flex;
  font-family: 'rift', Arial, Helvetica, sans-serif;
  font-style: normal;
  flex-direction: column;
  justify-content: flex-start;
  padding: 10% 5%;

  @media (min-width: ${breakpoints.tablet}) {
    padding: 5%;
  }
`
const Logo = styled.img`
  border: 3px solid #fff;
  border-radius: 15px;
  margin-top: 30px;
  width: 90px;

  @media (min-width: ${breakpoints.tablet}) {
    width: 120px;
  }
`

const Title = styled.h2`
  color: #fff;
  font-family: 'rift', Arial, Helvetica, sans-serif;
  font-size: 96px;
  font-weight: 700;
  line-height: 1;
  margin: 25px 0 15px 0;
  text-align: center;

  @media (min-width: ${breakpoints.tablet}) {
    font-size: 136px;
  }
`

const Subtitle = styled.p`
  color: #fff;
  font-family: 'TradeGothicPro', Arial, Helvetica, sans-serif;
  font-size: 24px;
  line-height: 1.5;
  margin: 6px;
  width: 280px;
  text-align: center;

  @media (min-width: ${breakpoints.mobile}) and (max-width: ${breakpoints.tablet}) {
    width: 350px;
  }

  @media (min-width: ${breakpoints.tablet}) {
    width: 500px;
  }

  span {
    display: inline;

    @media (min-width: ${breakpoints.tablet}) {
      display: block;
      margin-bottom: 10px;
    }
  }

  &:first-child {
    margin-top: 0;
  }
`

// FORM
// this holds the form inside of it, must mirror #pfb-signup-submission size
const FormContainer = styled.section`
  height: 390px;
  position: relative;
  width: 280px;

  @media (min-width: ${breakpoints.mobile}) and (max-width: ${breakpoints.tablet}) {
    height: 390px;
    width: 340px;
  }

  @media (min-width: ${breakpoints.tablet}) {
    height: 250px;
    width: 700px;
  }
`

const FormItself = styled.form`
  padding-bottom: 35px;
  position: absolute;
  top: 0;
  z-index: 20;
  left: 50%;
  transform: translateX(-50%);
`

const Hidden = styled.div`
  display: none;
`

const InputGroup = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media (min-width: ${breakpoints.tablet}) {
    flex-direction: row;
  }
`

// Corrects for alignment issues, fits into grid more tightly
const InputGroupLast = styled(InputGroup)`
  @media (min-width: ${breakpoints.tablet}) {
    margin-top: -15px;
  }
`

const InputBox = styled.input`
  background-color: ${colors.mediumGray};
  border-style: none;
  color: #fff;
  font-family: 'TradeGothicPro', Arial, Helvetica, sans-serif;
  font-size: 18px;
  margin: 15px auto 0 auto;
  display: inline-block;
  height: 40px;
  width: 260px;
  padding: 10px;
  text-transform: uppercase;

  /**
   * There is no set syntax for styling placeholder color, so this zoo is here
   * Opacity is required for mozilla for some reason
   * More here: https://css-tricks.com/almanac/selectors/p/placeholder/
   */ 
  &::placeholder {
    color: rgba(255,255,255,1);
  }

  &::-webkit-input-placeholder {
    color: rgba(255,255,255,1);
  }

  &::-moz-placeholder {
    color: rgba(255,255,255,1);
    opacity: 1;
  }

  &:-ms-input-placeholder {
    color: rgba(255,255,255,1);
  }

  @media (min-width: ${breakpoints.mobile}) {
    padding: 10px 20px;
    width: 300px;
  }

  @media (min-width: ${breakpoints.tablet}) {
    margin: 15px 7.5px;
  }
`

const InputSubmit = styled.input.attrs({ type: 'submit', value: 'submit' })`
  background-color: ${colors.red};
  border: 0;
  color: #fff;
  cursor: pointer;
  display: block;
  font-family: 'TradeGothicPro', Arial, Helvetica, sans-serif;
  font-size: 16px;
  font-weight: bold;
  margin-left: auto;
  margin-right: auto;
  margin-top: 15px;
  padding: 12px;
  text-transform: uppercase;
  width: 280px;

  &:hover {
    background-color: ${colors.red};
    transition: .2s ease-in-out;
  }

  @media (min-width: ${breakpoints.mobile}) {
    width: 340px;
  }
`

// Rule
const Rule = styled.div`
  height: 1px;
  background-color: #fff;
  width: 260px;
  margin: 0 auto;

  @media (min-width: ${breakpoints.tiny}) and (max-width: ${breakpoints.tablet}) {
    width: 320px;
  }

  @media (min-width: ${breakpoints.tablet}) {
    width: 700px;
  }

`

const FooterLink = styled.a`
  color: ${ colors.red };
  text-decoration: none;

  &:hover, &:visited, &:focus {
    color: ${ colors.red };
    text-decoration: none;
  }
`

const Success = styled.div`
  color: #fff;
  font-family: 'TradeGothicPro', Arial, Helvetica, sans-serif;
  font-size: 24px;
  line-height: 1.5;
  margin: 0 auto;
  opacity: 0;
  padding: 0;
  position: absolute;
  top: 35%;
  text-align: center;
  width: 100%;
  z-index: 10;
`

// Bottom link text
const LinkContainer = styled.div` 
  color: white;
  font-family: 'TradeGothicPro', Arial, Helvetica, sans-serif;
  font-size: 22px;
  line-height: 1.5;
  margin: 25px 0 0 0; // bottom margin comes from padding of footer container
  padding: 0 15px;
  text-align: center;

  a, a:visited, a:hover, a:focus {
    color: $red;
  }
`

function encode(data) {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&");
}

class Footer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  handleChange = (e) => {
    this.setState({[e.target.name]: e.target.value});
  }

  handleSubmit = (e) => {
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({ "form-name": "2019-pfb-annual-report-signup-form", ...this.state })
    })
      .then( () => {
        document.getElementById("pfb-signup-submission").classList.add('hide-form')
        document.getElementById("pfb-signup-result").classList.add('show-form-thanks')
      })
      .catch(
        error => alert("We couldn't add you to the list. Try again and if the problem persists, email placesforbikes@peopleforbikes.org and we'll manually add you to the list.")
      );

    e.preventDefault();
  };

  render() {
    return (
      <FooterContainer>
        <Logo src={ logo } alt="People for Bikes Logo" />
        <Title>THANKS</Title>
        <Subtitle>
          <span>for making 2019 a great year for biking. </span> 
          <span>Join People for Bikes to make 2020 even better.</span>
        </Subtitle>
        <FormContainer>
          <FormItself id="pfb-signup-submission" name="2019-pfb-annual-report-signup-form" data-netlify="true" data-netlify-honeypot="bot-field" onSubmit={ this.handleSubmit }>
            <Hidden>
              Don’t fill this out: <input name="bot-field" />
            </Hidden>
            <InputGroup>
              <InputBox type="text" name="first-name" id="pfb-signup-box-fname" placeholder="First Name" onChange={ this.handleChange } /> 
              <InputBox type="text" name="last-name" id="pfb-signup-box-lname" placeholder="Last Name" onChange={ this.handleChange } />
            </InputGroup>
            <InputGroupLast>
              <InputBox type="email" name="email" id="pfb-signup-box-email" placeholder="Email" onChange={ this.handleChange } /> 
              <InputBox type="text" name="zip-code" id="pfb-signup-box-zip" placeholder="Zip Code" onChange={ this.handleChange } />
            </InputGroupLast>
            <input type="hidden" name="form-name" value="2019-pfb-annual-report-signup-form" />
            <InputSubmit type="submit" className="submit-button" value="Sign Up" id="pfb-signup-button" />
          </FormItself>
          <Success id="pfb-signup-result">Thanks for signing up with People for Bikes!</Success>
        </FormContainer>
        <Rule />
        <LinkContainer>
          Read more about us at <FooterLink href="https://peopleforbikes.org" target="_blank" noopener noreferrer>peopleforbikes.org</FooterLink>
        </LinkContainer>
      </FooterContainer>
    )
  }
}

export default Footer