import React from "react"
import styled from "styled-components"

import Fade from 'react-reveal/Reveal';
import withReveal from 'react-reveal/withReveal';

import { breakpoints } from "../../css/styles"

/* Scoped styled */
const Container = withReveal(styled.section`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 50px 0;
  position: relative;
  z-index: 3;

  @media (min-width: ${ breakpoints.laptop }) {
    flex-direction: row;
  }
`, <Fade bottom duration={1500} delay={500} />);

const RideSpotSlide = ({children}) => {
  return (
    <>
      <Container>
        { children }
      </Container>
    </>
  )
}

export default RideSpotSlide