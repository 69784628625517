import React from "react"
import styled from "styled-components"

import arrowIcon from '../../images/arrow.svg'

const ScrollContainer = styled.section`
  animation: 'body-fade-in' 0.75s forwards;
  animation-delay: 2s;
  bottom: 2vh;
  left: 50%;
  opacity: 0;
  position: absolute;
  transform: translateX(-50%);
  z-index: 2;
`
const IconContainer = styled.div`
  align-items: center;
  background-color: rgba(255,255,255,0.8);
  border-radius: 22px;
  display: flex;
  height: 45px;
  justify-content: center;
  width: 45px;
 `
const Icon = styled.img`
  height: 30px;
  margin: 0;
  transform: rotate(90deg);
  width: 30px;
`
const Text = styled.p`
  color: white;
  font-size: 12px;
  margin: 0;
  padding-top: 8px;
  text-align: center;
  text-transform: uppercase;
`

const IntroArrow = () => {
  return (
    <>
      <ScrollContainer>
        <IconContainer>
          <Icon src= { arrowIcon } alt="Arrow icon, indicating the user should scroll down the page" />
        </IconContainer>
        <Text>Scroll</Text>
      </ScrollContainer>

    </>
  )
}

export default IntroArrow