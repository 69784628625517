import React from "react"
import styled from "styled-components"

import { breakpoints } from "../../css/styles"

/* Article */
const Article = styled.article`
  margin: 0% 8%;

  @media (min-width: ${breakpoints.mobile}) and (max-width: ${breakpoints.tablet}) {
    margin: 0% 10%;
  }

  @media (min-width: ${breakpoints.tablet}) {
    margin: 0 20%;
  }
`

const Title = styled.h2`
  font-size: 40px;
  font-weight: 700;
  line-height: 1.1;
  margin: 0 0 15px 0;

  @media (min-width: ${breakpoints.phablet}) {
    font-size: 56px;
  }
`

const Lede = styled.p`
  color: #fff;
  font-family: 'proxima-nova', sans-serif;
  font-size: 20px;
  font-weight: 800;
  line-height: 1.4;
  margin-bottom: 35px;

  a, a:visited, a:hover, a:focus {
    color: #fff;
    text-decoration: underline;
  }

  @media (min-width: ${breakpoints.phablet}) {
    font-size: 28px;
    line-height: 1.2;
  }
`

class TitleArea extends React.Component {
  render() {
    return (
      <>
        <Article>
          <Title> { this.props.title } </Title>
          <Lede> { this.props.lede } </Lede>
        </Article>
      </>
    )
  }
}

export default TitleArea