import React from "react"
import { graphql } from "gatsby"
import Img from "gatsby-image"

import Layout from "../components/Global/layout"
import SEO from "../components/Global/seo"

import BlueGradient from '../components/Story/BlueGradient'
import MicroNav from '../components/Story/MicroNav'
import TitleArea from '../components/Story/TitleArea'
import ImageContainer from '../components/Global/ImageContainer'
import Content from '../components/Story/Content'
import GrayGradient from '../components/Story/GrayGradient'
import BlueContent from '../components/Story/BlueContent'
import RideSpotLogo from '../components/Story/RideSpotLogo'
import Spacer from '../components/Story/Spacer'
import Related from '../components/Story/Related'
import RideSpotContainer from '../components/Story/RideSpotContainer'
import RideSpotImgContainer from '../components/Story/RideSpotImgContainer'
import RideSpot from '../components/Global/RideSpot'

// Randomizer, not very random though but random enough
function getRandomNumber(min, max) {
  return Math.random() * (max - min) + min;
}

const Ridespot = (props) => {

  return (  
    <Layout>
      <SEO title="Ridespot App Success" />

      {/* Story Section */}
      <BlueGradient>
        <MicroNav />
        <TitleArea
          title="Introducing Ride Spot, Our Program to Help Every Bike Rider"
          lede="PeopleForBikes is  working to provide people with the information they need to discover and enjoy great bike rides and bike events wherever they live and every place they visit."
        />  
        <ImageContainer
          title="Promo image for Ride Spot"
          alt="Promo image for Ride Spot"
          fluid= { props.data.ridespotImage.childImageSharp.fluid }
        />
        <Content 
          story={[
            <p key={ getRandomNumber(1,100000) }>Too many people buy bikes, begin riding, but then immediately stop and don’t start again. Why? Many don’t have positive first-time experiences--often because they picked busy roads packed with cars and trucks that make cycling more stressful and less enjoyable (even when great riding options were nearby). Others battle basic bike equipment challenges such as under-inflated tires, uncomfortable saddles or a helmet that doesn’t fit. Too many people don’t know where to turn for guidance on where to ride or how to properly prepare. Ride Spot is a contemporary solution that delivers what new bike riders want and need.</p>,
            <p key={ getRandomNumber(1,100000) }>In 2019, we:</p>,
            <ul key={ getRandomNumber(1,100000) }>
              <li key={ getRandomNumber(1,100000) }>Mapped and shared more than 130,000 bike routes</li>
              <li key={ getRandomNumber(1,100000) }>Developed more than 10,000 New PeopleForBikes Supporters</li>
              <li key={ getRandomNumber(1,100000) }>Engaged nearly 1,000 bicycle retailers as Ride Spot Partners</li>
              <li key={ getRandomNumber(1,100000) }>Partnered with Silicon Valley tech corporations looking to increase bike riding by employees (both commuting and recreational)</li>
              <li key={ getRandomNumber(1,100000) }>Were recognized as a top technology innovation project by Fast Company in its prestigious annual awards</li>
            </ul>,
          ]} />
        <Spacer />
      </BlueGradient>

      {/* Ridespot Section */}
      <GrayGradient>
        <RideSpotLogo
          title="PFB to Diaz Tacos!" 
        />
        <ImageContainer
          title="Google Map of Boulder Route"
          alt="Google Map of Boulder Route"
          fluid={ props.data.ridespotMapImage.childImageSharp.fluid }
        />
        <BlueContent 
          location="Boulder, CO"
          distance="7.1 miles"
          elevation="230 feet"
          story='At PeopleForBikes there are two things we crave: fun bike adventures and tacos. So when our graphic designer, Alex "Butters" Butterbrodt, learned of a nearby food truck that serves highly acclaimed tacos, it wasn’t long before we scheduled an afternoon Tuesday ride to get a taste for ourselves.'
          link="https://ridespot.org/rides/122193"
        />  
        <RideSpotImgContainer>
          <Img
            title="Ride imagery for Boulder, CO"
            alt="Ride imagery for Boulder, CO"
            fluid={ props.data.ridespotRideImageOne.childImageSharp.fluid }
          />
          <Img
            title="Ride imagery for Boulder, CO"
            alt="Ride imagery for Boulder, CO"
            fluid={ props.data.ridespotRideImageTwo.childImageSharp.fluid }
          />
        </RideSpotImgContainer>
        <Spacer />
      </GrayGradient>
      
      { /* Related Content */ }
      <Related 
        prevLocationName="Quality"
        prevLocationPath="/quality"
        nextLocationName="Merger"
        nextLocationPath="/merger"
      />

      { /* Ride Spot Promo */ }
      <RideSpotContainer>
        <RideSpot />
      </RideSpotContainer>
      
    </Layout>
  )
}

export default Ridespot

export const ridespotImageQuery = graphql`
  query {
    ridespotImage: file(relativePath: { eq: "slides/3-PFB-RideSpot.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1000, quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    },
    ridespotMapImage: file(relativePath: { eq: "maps/PFB_RS_Maps_Story3_MAP.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1000, quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    },
    ridespotRideImageOne: file(relativePath: { eq: "ride-images/Ride3_Image1.png" }) {
      childImageSharp {
        fluid(maxWidth: 1000, quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    },  
    ridespotRideImageTwo: file(relativePath: { eq: "ride-images/Ride3_Image2.png" }) {
      childImageSharp {
        fluid(maxWidth: 500, quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    },       
  }
`