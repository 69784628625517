import React from "react"
import styled from "styled-components"

import { breakpoints } from '../../css/styles'

// Containers
const Container = styled.aside`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 10px;
  margin: 0% 8%;

  @media (min-width: ${breakpoints.mobile}) and (max-width: ${breakpoints.tablet}) {
    margin: 0% 10%;
  }

  @media (min-width: ${ breakpoints.tablet }) {
    grid-template-columns: 1fr 1fr;
    margin: 0 20%;
  }
`

const RideSpotImgContainer = ({children}) => {
  return (
    <>
      <Container>
        {children}
      </Container>
    </>
  )
}
  
export default RideSpotImgContainer