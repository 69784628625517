import React from "react"
import Link from 'gatsby-link'
import styled from "styled-components"

import { breakpoints } from '../../css/styles'
import Arrow from '../../images/arrow.svg'
import PinImg from '../../images/ride-spot-pin.png'
import Streets from '../../images/streets-2.svg'

// Containers
const Container = styled.aside`
  background-color: black;
`

const InnerContainer = styled.section`
  padding: 5% 10%;
`

const ImageContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  @media (min-width: ${breakpoints.tablet}) {
    flex-wrap: nowrap;
  }
`

// Arrows
const ArrowLink = styled(Link)`
  display: none;
  text-decoration: none;

  @media (min-width: ${breakpoints.tablet}) {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  &:hover {
    text-decoration: none;
  }
`

const IconContainer = styled.div`
  align-items: center;
  background-color: #fff;
  border-radius: 22px;
  display: flex;
  height: 45px;
  justify-content: center;
  margin: 0 25px;
  width: 45px;
`
const IconLeft = styled.img`
  margin: 0;
  transform: rotate(180deg);
  width: 30px;
`
const IconRight = styled.img`
  margin: 0;
  width: 30px;
`
const RelatedOne = styled(Link)`
  align-items: center;
  background-image: url(${ Streets }), linear-gradient(
    #61bbe2, 
    #103d54
  );
  background-position: center center;
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 170px;
  margin: 0 0 2% 0;
  text-decoration: none;
  width: 100%;

  @media (min-width: ${breakpoints.tablet}) {
    margin: 0 1% 0 0;
    width: 48%;
  }

  &:visited, &:hover, &:focus {
    color: white;
    text-decoration: none;
  }
`

const RelatedTwo = styled(Link)`
  align-items: center;
  background-image: url(${ Streets }), linear-gradient(
    #61bbe2, 
    #103d54
  );
  background-position: center center;
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0;
  min-height: 170px;
  text-decoration: none;
  width: 100%;

  @media (min-width: ${breakpoints.tablet}) {
    margin: 0 0 0 1%;
    width: 48%;
  }

  &:visited, &:hover, &:focus {
    color: white;
    text-decoration: none;
  }
`

const Pin = styled.img`
  margin: 0 0 5px 0;
  width: 60px;
`

const RelatedText = styled.p`
  color: white;
  font-family: 'AntebBold', Arial, Helvetica, sans-serif;
  font-size: 36px;
  margin: 0;
  font-weight: bold;
  text-align: center;
  text-transform: uppercase; 
`

class Related extends React.Component {
  render() {
    return (
      <Container>
        <InnerContainer>
          <ImageContainer>
            <ArrowLink to={ this.props.prevLocationPath } >
              <IconContainer>
                <IconLeft src={ Arrow }  alt="Navigate to the next location in the PFB Annual Report" />
              </IconContainer>
            </ArrowLink>
            <RelatedOne to={ this.props.prevLocationPath } >
              <Pin src={ PinImg } alt="Map pin" />
              <RelatedText>{ this.props.prevLocationName }</RelatedText>
            </RelatedOne>
            <RelatedTwo to={ this.props.nextLocationPath }>
              <Pin src={ PinImg } alt="Map pin" />
              <RelatedText>{ this.props.nextLocationName }</RelatedText>
            </RelatedTwo>
            <ArrowLink to={ this.props.nextLocationPath }>
              <IconContainer>
                <IconRight src={ Arrow }  alt="Navigate to the next location in the PFB Annual Report" />
              </IconContainer>
            </ArrowLink>
          </ImageContainer>
        </InnerContainer>
      </Container>
    )
  }
}

export default Related