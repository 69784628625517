import React from "react"
import { graphql } from "gatsby"
import Img from "gatsby-image"

import Layout from "../components/Global/layout"
import SEO from "../components/Global/seo"

import BlueGradient from '../components/Story/BlueGradient'
import MicroNav from '../components/Story/MicroNav'
import TitleArea from '../components/Story/TitleArea'
import ImageContainer from '../components/Global/ImageContainer'
import Content from '../components/Story/Content'
import GrayGradient from '../components/Story/GrayGradient'
import BlueContent from '../components/Story/BlueContent'
import RideSpotLogo from '../components/Story/RideSpotLogo'
import Spacer from '../components/Story/Spacer'
import Related from '../components/Story/Related'
import RideSpotContainer from '../components/Story/RideSpotContainer'
import RideSpotImgContainer from '../components/Story/RideSpotImgContainer'
import RideSpot from '../components/Global/RideSpot'

// Randomizer, not very random though but random enough
function getRandomNumber(min, max) {
  return Math.random() * (max - min) + min;
}

const Ebikes = (props) => {

  return (  
    <Layout>
      <SEO title="e-bike Standards Success" />

      {/* Story Section */}
      <BlueGradient>
        <MicroNav />
        <TitleArea
          title="Growing e-bike Use by Partnering with States and Federal Agencies"
          lede="PeopleForBikes continues to lead the industry in promoting and supporting e-bike use."
        />  
        <ImageContainer
          title="Promo image for e-bikes"
          alt="Promo image for e-bikes"
          fluid= { props.data.ebikesImage.childImageSharp.fluid }
        />            
        <Content 
          story={[
            <p key={ getRandomNumber(1,100000) }>E-bikes continue to be a brilliant success story for bicycling participation and for all types of bike businesses--suppliers, retailers, events, tours, bike sharing and tourism. Most important: people of all ages and physical abilities are rediscovering the joys of bicycling by hopping onto e-bikes and benefiting from the silent power assist that these bikes provide. E-bikes are generating a global cycling revolution with recreational and transportation benefits. The e-bike smile&mdash;a look of delight and simple satisfaction&mdash;can be seen all around the world.</p>,
            <p key={ getRandomNumber(1,100000) }>In addition to our outreach efforts, we advocated for better e-bike policies on federal lands and provided rider and land manager resources on those new policies.</p>,
          ]} />
        <Spacer />
      </BlueGradient>

      {/* Ridespot Section */}
      <GrayGradient>
        <RideSpotLogo
          title="Corral Trail Loop-eMTB" 
        />
        <ImageContainer
          title="Google Map of South Lake Tahoe Route"
          alt="Google Map of South Lake Tahoe Route"
          fluid={ props.data.ebikesMapImage.childImageSharp.fluid }
        />
        <BlueContent 
          location="South Lake Tahoe, CA"
          distance="8.8 miles"
          elevation="1600 feet"
          link="https://ridespot.org/rides/138443"
        />  
        <RideSpotImgContainer>
          <Img
            title="Ride imagery for South Lake Tahoe, CO"
            alt="Ride imagery for South Lake Tahoe, CO"
            fluid={ props.data.ebikesRideImageOne.childImageSharp.fluid }
          />
        </RideSpotImgContainer>
        <Spacer />
      </GrayGradient>
      
      { /* Related Content */ }
      <Related 
        prevLocationName="Merger"
        prevLocationPath="/merger"
        nextLocationName="Measurement"
        nextLocationPath="/measurement"
      /> 

      { /* Ride Spot Promo */ }
      <RideSpotContainer>
        <RideSpot />
      </RideSpotContainer>
      
    </Layout>
  )
}

export default Ebikes

export const ebikesImageQuery = graphql`
  query {
    ebikesImage: file(relativePath: { eq: "slides/5-PFB-Ebike.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1000, quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    },
    ebikesMapImage: file(relativePath: { eq: "maps/PFB_RS_Maps_Story5_MAP.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1000, quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    },
    ebikesRideImageOne: file(relativePath: { eq: "ride-images/Ride5_Image1.png" }) {
      childImageSharp {
        fluid(maxWidth: 1000, quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    },        
  }
`