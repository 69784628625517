import React from "react"

import Layout from "../components/Global/layout"
import SEO from "../components/Global/seo"
import NotFound from '../components/Global/NotFound'

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <NotFound path="/" /> 
  </Layout> 
)

export default NotFoundPage
