import React from "react"
import styled from "styled-components"

import { breakpoints, colors } from "../../css/styles"

/* Article */
const Article = styled.article`
  color: ${ colors.darkestBlue };
  font-size: 20px;
  line-height: 1.4;
  margin: 0% 8%;

  @media (min-width: ${breakpoints.mobile}) and (max-width: ${breakpoints.tablet}) {
    margin: 0% 10%;
  }

  @media (min-width: ${breakpoints.tablet}) {
    margin: 0 20%;
  }
`

const Paragraph = styled.p`
  color: ${ colors.darkestBlue };
  font-size: 20px;
  line-height: 1.4;
  margin: 15px 0;
`
const Metadata = styled.div`
  margin: 8px 0;

  span {
    font-weight: 700;
  }
`
const ExternalLink = styled.div`
  color: ${ colors.darkestBlue };
  display: block;
  margin: 15px 0 35px 0;
  
  &:visited, &:hover, &:focus {
    color: ${ colors.darkestBlue };
  }
`


const Content = (props) => {
  return (
    <>
      <Article>
        <Metadata> <span>Location:</span> {props.location} </Metadata>
        <Metadata> <span>Distance:</span> {props.distance} </Metadata>
        <Metadata> <span>Elevation Gain:</span> {props.elevation} </Metadata>
        <Paragraph> { props.story } </Paragraph>
        <ExternalLink>
          Read about the entire ride at:&nbsp;
          <a href={ props.link } target="_blank" rel="noopener noreferrer">{ props.link }</a>
        </ExternalLink>
      </Article>
    </>
  )
}

export default Content