import React from "react"
import styled from "styled-components"

import { breakpoints, colors } from '../../css/styles'
import Logo from "../../images/ride-spot-logo.png"

const LogoFun = styled.img`
  margin: 0;
  padding: 4% 10% 0 10%;
  width: 200px;

  @media (min-width: ${breakpoints.mobile}) and (max-width: ${breakpoints.phablet}) {
    width: 300px;
  }

  @media (min-width: ${breakpoints.phablet}) {
    width: 400px;
  }

  @media (min-width: ${breakpoints.tablet}) {
    padding: 2% 20% 0 20%;
  }
`
const Title = styled.h2`
  color: ${ colors.darkestBlue };
  font-size: 24px;
  font-weight: 400;
  line-height: 1.1;
  padding: 4% 10% 0 10%;

  @media (min-width: ${breakpoints.phablet}) {
    font-size: 36px;
  }

  @media (min-width: ${breakpoints.tablet}) {
    padding: 2% 20% 0 20%;
  }
`

const RideSpotLogo = (props) => {
  return (
    <>
      <LogoFun src={ Logo } alt="Ride Spot Logo" />
      <Title> { props.title } </Title>
    </>
  )
}

export default RideSpotLogo