import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"

import Fade from 'react-reveal/Reveal';
import withReveal from 'react-reveal/withReveal';

import { breakpoints, colors } from "../../css/styles"

/* Scoped styled */
const Container = withReveal(styled.section`
  color: white;
  margin: 5% 2%; 
  max-width: 550px;
  text-align: center;

  @media(min-width: ${breakpoints.laptop}) {
    margin: 2% 5%;
    text-align: left;
  }
`, <Fade bottom duration={1500} delay={500} />);

const Title = styled.h2`
  color: ${ colors.darkestBlue };
  font-size: 28px;
  font-weight: 700;
  line-height: 1.1;
  margin: 0 0 15px 0;

  @media (min-width: ${breakpoints.phablet}) {
    font-size: 42px;
  }
`
const Lede = styled.h3`
  color: #fff;
  font-family: 'proxima-nova', sans-serif;
  font-size: 16px;
  font-weight: 800;
  line-height: 1.4;
  margin: 0 auto 15px auto;
  max-width: 400px;

  @media (min-width: ${breakpoints.tablet}) {
    font-size: 20px;
    line-height: 1.2;
    margin: 0 0 15px 0;
    max-width: none;
  }
`
const Body = styled.p`
  color: #fff;
  display: none;
  font-size: 16px;
  line-height: 1.4;
  margin: 0;

  a, a:visited, a:hover, a:focus {
    color: #fff;
    text-decoration: underline;
  }

  @media(min-width: ${breakpoints.laptop}) {
    display: block;
  }
`
const ReadMore = styled(Link)`
  background-color: ${ colors.lightGray };
  border-radius: 4px;
  color: ${ colors.darkestBlue };
  display: block;
  font-size: 16px;
  font-weight: 700;
  margin: 15px auto 0 auto;
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  transition: 0.2s ease-in-out;
  width: 150px;

  &:visited, &:focus {
    text-decoration: none;
  }

  &:hover {
    background-color: ${ colors.lightestGray };
    text-decoration: none;
  }

  @media(min-width: ${breakpoints.laptop}) {
    margin: 15px 0 0 0;
  }
`

const SlideText = (props) => {
  return (
    <>
      <Container>
        <Title>{ props.title }</Title>
        <Lede>{ props.lede }</Lede>
        <Body>{ props.body }</Body>
        <ReadMore to={ props.path }>Read The Story</ReadMore>
      </Container>
    </>
  )
}

export default SlideText