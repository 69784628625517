// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-ebikes-js": () => import("../src/pages/ebikes.js" /* webpackChunkName: "component---src-pages-ebikes-js" */),
  "component---src-pages-index-js": () => import("../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-investment-js": () => import("../src/pages/investment.js" /* webpackChunkName: "component---src-pages-investment-js" */),
  "component---src-pages-measurement-js": () => import("../src/pages/measurement.js" /* webpackChunkName: "component---src-pages-measurement-js" */),
  "component---src-pages-merger-js": () => import("../src/pages/merger.js" /* webpackChunkName: "component---src-pages-merger-js" */),
  "component---src-pages-opportunity-js": () => import("../src/pages/opportunity.js" /* webpackChunkName: "component---src-pages-opportunity-js" */),
  "component---src-pages-policy-js": () => import("../src/pages/policy.js" /* webpackChunkName: "component---src-pages-policy-js" */),
  "component---src-pages-quality-js": () => import("../src/pages/quality.js" /* webpackChunkName: "component---src-pages-quality-js" */),
  "component---src-pages-ridespot-js": () => import("../src/pages/ridespot.js" /* webpackChunkName: "component---src-pages-ridespot-js" */)
}

