import React from "react"
import styled from "styled-components"

import Fade from 'react-reveal/Reveal';
import withReveal from 'react-reveal/withReveal';

import { breakpoints } from "../../css/styles"

/* Scoped styled */
const Container = withReveal(styled.section`
  flex-basis: 40%;
  margin: 5% 2%; 

  @media(min-width: ${breakpoints.tablet}) {
    margin: 2% 15px 2% 5%;
  }
`, <Fade bottom duration={1500}/>);

const MapImage = styled.img`
  height: auto;
  margin: auto;
  max-width: 100%;

  @media(min-width: ${breakpoints.phablet}) {
    max-width: 500px;
  }
`

const SlideMapImage = (props) => {
  return (
    <>
      <Container>
        <MapImage 
          src={ props.imageSource } 
          alt={ props.imageAltText } />
      </Container>
    </>
  )
}

export default SlideMapImage