import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"

import { breakpoints } from "../../css/styles"
import arrowIcon from '../../images/arrow.svg'

const Container = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 8%;
  text-decoration: none;

  @media (min-width: ${breakpoints.mobile}) and (max-width: ${breakpoints.tablet}) {
    padding: 8% 10% 4% 10%;
  }

  @media (min-width: ${breakpoints.tablet}) {
    padding: 5% 20% 2% 20%;
  }

  &:visited, &:hover, &:focus {
    text-decoration: none;
  }
`
const IconContainer = styled.div`
  align-items: center;
  background-color: #fff;
  border-radius: 22px;
  display: flex;
  height: 45px;
  justify-content: center;
  width: 45px;
 `
const Icon = styled.img`
  height: 30px;
  margin: 0;
  transform: rotate(180deg);
  width: 30px;
`
const Paragraph = styled.p`
  color: #fff;
  font-size: 16px;
  font-weight: bold;
  margin: 0;
  padding-left: 15px;
  text-transform: uppercase;
`
  
// MicroNav displays a simple icon that jumps users from a location page to the location index
class MicroNav extends React.Component {
  render() {
    return (
      <>
        <Container to="/">
          <IconContainer>
            <Icon src= { arrowIcon } alt="Arrow icon, returns user to locations landing page" />
          </IconContainer>
          <Paragraph>Return to Stories</Paragraph>
        </Container>
      </>
    )
  }
}

export default MicroNav