import React from 'react'
import styled from 'styled-components'

import { breakpoints, colors } from '../../css/styles'
import bmxDesktop from '../../images/bmx-rider.png'
import bmxMobile from '../../images/bmx-rider-mobile.png'

// 404 Styles
const Container = styled.section`
  align-items: center;
  background-color: ${colors.red};
  color: #fff;
  display: flex;
  flex-direction: column;
  height: calc(100vh - 140px);
  justify-content: center;
  width: 100%;

  @media (min-width: ${breakpoints.tablet}) {
    height: calc(100vh - 95px);
  }
`

const MainImg = styled.div`
  background-image: url(${ bmxMobile });
  height: 251px;
  position: relative;
  width: 300px;
  z-index: 10;

  @media (min-width: ${breakpoints.mobile}) {
    background-image: url(${ bmxDesktop });
    height: 290px;
    width: 346px;
  }

  @media (min-width: ${breakpoints.tablet}) {
    top: 50px;
  }
`

const TextContainer = styled.div`
  padding: 0 15px;
  position: relative;
  top: -20px;

  @media (min-width: ${breakpoints.mobile}) {
    top: -50px;
  }
`

const Title = styled.h2`
  font-family: 'rift' ,sans-serif;
  font-size: 160px;
  font-weight: 700;
  line-height: .8;
  margin: 0;
  text-align: center;

  @media (min-width: ${breakpoints.mobile}) and (max-width: ${breakpoints.tablet}) {
    font-size: 250px;
  }

  @media (min-width: ${breakpoints.tablet}) {
    font-size: 380px;
  }
`

const Content = styled.p`
  font-size: 22px;
  line-height: 1.6;
  text-align: center;
  text-transform: uppercase;

  a, a:visited, a:focus, a:hover {
    color: #fff;
    text-decoration: underline;
  }

  span {
    display: block;
  }
`

class NotFound extends React.Component {
  render() {
    return(
      <Container>
        <MainImg />
        <TextContainer>
          <Title>404</Title>
          <Content>
            <span>You took a wrong turn.</span> 
            <a href={ this.props.path }>Click here to jump back in</a>.
          </Content>
        </TextContainer>
      </Container>
    )
  }
}

export default NotFound