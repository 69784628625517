import React from "react"
import styled from "styled-components"

import { breakpoints } from "../../css/styles"

/* Scoped styled */
const ContentBox = styled.article`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 5%;
  min-height: 110vh;

  @media(min-width: ${breakpoints.laptop}) {
    flex-direction: row;
    margin: 0 2%;
    min-height: 85vh;
  }
`

const Slide = ({ children }) => {
  return (
    <>
      <ContentBox>
        { children }
      </ContentBox>
    </>
  )
}

export default Slide