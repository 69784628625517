import React from "react"
import styled from "styled-components"

import { breakpoints, colors } from '../../css/styles'

import Streets from '../../images/streets-2.svg'

// Containers
const Container = styled.aside`
  align-items: center;
  background-image: url(${ Streets }), linear-gradient(
    ${colors.lightBlue}, 
    ${colors.darkBlue}
  );
  background-position: center center;
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 50px 0;

  @media (min-width: ${ breakpoints.laptop }) {
    flex-direction: row;
  }
`

const RideSpotContainer = ({children}) => {
  return (
    <>
      <Container>
        {children}
      </Container>
    </>
  )
}
  
export default RideSpotContainer